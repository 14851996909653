<template>
  <el-container>
    <TopBar></TopBar>
    <el-main>
      <el-row>
        <router-view></router-view>
      </el-row>
    </el-main>
  </el-container>
</template>

<style lang="scss" scoped>
.el-main {
  position: absolute;
  top: 55px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0;

  .el-row {
    height: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .el-main {
    top: 65px !important;
    left: 265px !important;
    z-index: 1;
  }
}
</style>

<script>
// components import
import { mapGetters } from 'vuex'
import TopBar from './TopBar'

export default {
  components: {
    TopBar
  },
  computed: {
    ...mapGetters([
      'breadcrumbs',
      'pageTitle'
    ])
  }
}
</script>
