<template>
  <div class="topbar">
    <el-header class="topbar__header topbar__header--fixed" :class="{ 'topbar__header--show': showHeader }">
      <div class="topbar__header__search">
        <!-- <el-input suffix-icon="el-icon-search" size="medium" placeholder="Search"></el-input> -->
        <span style="font-weight: 400; color: #555;">Welcome to NRCP Inventory Management System!</span>
      </div>
      <div class="topbar__header__control">
        <BaseDropdown v-if="showSetting">
          <template slot="menu">
            <span class="topbar__header__control__icon"><i class="el-icon-set-up"></i></span>
          </template>
          <template slot="header">
            <div class="topbar__header__control__title"><span>Settings</span></div>
          </template>
          <template slot="menu-list">
            <div class="topbar__header__control__menu">
              <div class="topbar__header__control__menu__item topbar__header__control__menu__item--border-right" @click="handleCompanyDetails">
                <div class="topbar__header__control__menu__item__icon"><img src="@/assets/company-icon.png" alt="company-icon"></div>
                <span class="topbar__header__control__menu__item__title">Company Details</span>
              </div>
              <div class="topbar__header__control__menu__item" @click="handleConfigurations">
                <div class="topbar__header__control__menu__item__icon"><img src="@/assets/settings-icon.png" alt="settings-icon"></div>
                <span class="topbar__header__control__menu__item__title">Configurations</span>
              </div>
            </div>
          </template>
        </BaseDropdown>
        <div class="topbar__header__control__user" @click="handleShowProfile">
          <span class="topbar__header__control__user__greeting">Hi, </span>
          <span class="topbar__header__control__user__name">{{ user.name }} </span>
          <span class="topbar__header__control__user__image">{{ user.name.substring(0, 1).toUpperCase() }}</span>
        </div>
      </div>
    </el-header>
    <el-header class="hidden-lg-and-up topbar__header topbar__header--fixed topbar__header--dark">
      <div class="topbar__header__brand">
        <img src="@/assets/logo.png" alt="gefyra">
      </div>
      <div class="topbar__header__toggle">
        <div class="topbar__header__toggle__hamburger" :class="{ 'topbar__header__toggle__hamburger--active': show }" @click="toggleSidebar">
          <div class="topbar__header__toggle__hamburger__line topbar__header__toggle__hamburger__line--top"></div>
          <div class="topbar__header__toggle__hamburger__line topbar__header__toggle__hamburger__line--middle"></div>
          <div class="topbar__header__toggle__hamburger__line topbar__header__toggle__hamburger__line--bottom"></div>
        </div>
        <div class="topbar__header__toggle__header" @click="toggleHeader">
          <span class="topbar__header__toggle__header__icon" :class="{ 'topbar__header__toggle__header__icon--active': showHeader }"><i class="el-icon-user-solid"></i></span>
        </div>
      </div>
    </el-header>
    <transition name="fade">
      <div class="topbar__mask" v-if="showProfile" @click="showProfile = false"></div>
    </transition>
    <transition name="fade">
      <div class="topbar__mask topbar__mask--bottom" v-if="show" @click="toggleSidebar"></div>
    </transition>
    <transition name="slide">
      <div class="topbar__user-drawer" v-if="showProfile">
        <div class="topbar__user-drawer__header">
          <span class="topbar__user-drawer__header__title">User Profile</span>
          <span class="topbar__user-drawer__header__button" @click="showProfile = false"><i class="el-icon-close"></i></span>
        </div>
        <div class="topbar__user-drawer__profile">
          <div class="topbar__user-drawer__profile__image">
            <img v-if="user.avatar_url" :src="user.avatar_url" alt="">
            <img v-else src="@/assets/avatar.jpg" alt="">
          </div>
          <div class="topbar__user-drawer__profile__content">
            <span class="topbar__user-drawer__profile__content__name">{{ user.name }}</span>
            <span class="topbar__user-drawer__profile__content__position">{{ user.position }}</span>
            <span class="topbar__user-drawer__profile__content__email"><i class="el-icon-message"></i> {{ user.email }}</span>
            <span class="topbar__user-drawer__profile__content__button">
              <el-button size="mini" type="primary" plain @click="handleLogout">Logout</el-button>
            </span>
          </div>
        </div>
        <div class="topbar__user-drawer__divider"></div>
        <div class="topbar__user-drawer__menu">
          <div class="topbar__user-drawer__menu__menu-item" @click="handleMyProfile">
            <div class="topbar__user-drawer__menu__menu-item__image">
              <img src="@/assets/profile-icon.png" alt="profile-icon">
            </div>
            <div class="topbar__user-drawer__menu__menu-item__content">
              <span class="topbar__user-drawer__menu__menu-item__content__title">My Profile</span>
              <span class="topbar__user-drawer__menu__menu-item__content__subtitle">Account settings and more</span>
            </div>
          </div>
          <!-- <div class="topbar__user-drawer__menu__menu-item topbar__user-drawer__menu__menu-item--no-margin">
            <div class="topbar__user-drawer__menu__menu-item__image">
              <img src="@/assets/inbox-icon.png" alt="profile-icon">
            </div>
            <div class="topbar__user-drawer__menu__menu-item__content">
              <span class="topbar__user-drawer__menu__menu-item__content__title">My Messages</span>
              <span class="topbar__user-drawer__menu__menu-item__content__subtitle">Inbox and Tasks</span>
            </div>
          </div> -->
        </div>
        <div class="topbar__user-drawer__divider"></div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.topbar {
  &__header {
    background-color: #fff;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 55px !important;
    transition: .3s all;

    &--fixed {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    &--dark {
      background: #070614;
    }

    &--show {
      top:55px;
    }

    &__search {
      display: flex;
      align-items: center;
    }

    &__brand {
      display: flex;
      width: 40px;
      align-items: center;
    }

    &__control {
      display: flex;
      align-items: stretch;

      &__icon {
        font-size: 25px;
        color: #409EFF;
      }

      &__title {
        font-size: 17px;
        font-weight: 400;
        margin-right: auto;
        margin-left: auto;
      }

      &__menu {
        display: flex;

        &__item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 150px;
          transition: .3s all;
          cursor: pointer;

          &--border-right {
            border-right: 1px solid #ecf0f3;
          }

          &:hover {
            background: #f3f6f9;
          }

          &__icon {
            img {
              height: 50px;
              width: 50px;
            }
          }

          &__title {
            font-weight: 500;
            font-size: 15px;
          }
        }
      }

      &__user {
        display: flex;
        align-items: center;
        margin-left: 5px;
        padding: 0 7px;
        transition: .3s all;
        border-radius: 5px;
        cursor: pointer;

        &__greeting {
          margin: 0 2px;
        }

        &__name {
          font-weight: 500;
          margin-right: 7px;
        }

        &__image {
          background: rgba(10, 81, 187, 0.15);
          color: #409EFF;
          font-size: 18px;
          font-weight: 600;
          padding: 5px 10px;
          border-radius: 5px;
        }
      }
    }

    &__toggle {
      display: flex;
      align-items: center;

      &__hamburger {
        width: 27px;
        cursor: pointer;

        $this: &;

        &--active {
          & > #{$this}__line {
            background: #409EFF;
          }

          #{$this}__line {
            &--top {
              transform: rotatez(-45deg);
            }

            &--middle {
              opacity: 0;
            }

            &--bottom {
              transform: rotatez(45deg);
            }
          }
        }

        &__line {
          height: 3px;
          border-radius: 3px;
          background: #484f66;
          margin: 6px 0;
          transition: .3s all;

          &--top {
            transform-origin: right top;
            transform: rotateZ(0);
          }

          &--bottom {
            transform-origin: right bottom;
            transform: rotateZ(0);
          }
        }

        &:hover {
          & > #{$this}__line {
            background: #409EFF;
          }
        }
      }

      &__header {
        margin-left: 10px;
        color: #484f66;
        font-size: 25px;

        &__icon {
          transition: .3s all;
          cursor: pointer;

          &:hover {
            color: #409EFF;
          }

          &--active {
            color: #409EFF;
          }
        }
      }
    }
  }

  &__mask {
    background: rgba(0, 0, 0, .1);
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 400 !important;

    &--bottom {
      z-index: 0 !important;
    }
  }

  &__user-drawer {
    position: fixed;
    z-index: 5600;
    right: 0;
    top: 0;
    bottom: 0;
    width: 375px;
    background: #fff;
    box-shadow: 0px 1px 4px 2px #ccc;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px;

      &__title {
        font-size: 18px;
        font-weight: 500;
        margin-right: 5px;
        color: #555;
      }

      &__subtitle {
        font-size: 13px;
        color: #888;
        flex-grow: 1;
      }

      &__button {
        display: flex;
        align-items: center;
        background: #eee;
        border-radius: 2px;
        padding: 5px 3px;
        font-size: 13px;
        transition: .3s all;
        cursor: pointer;

        &:hover {
          color: #fff;
          background: #409EFF;
        }
      }
    }

    &__profile {
      display: flex;
      align-items: center;
      margin: 0 30px;

      &__image {
        margin-right: 10px;
        display: flex;
        align-items: center;

        img {
          width: 100px;
          height: 100px;
          border-radius: 10px;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;

        &__name {
          font-size: 18px;
          font-weight: 500;
          line-height: 18px;
          margin-bottom: 5px;
        }

        &__position, &__email {
          margin-bottom: 2px;
          font-size: 14px;
          color: #b5b5c3;
        }

        &__email {
          i {
            color: #409EFF;
          }
        }
      }
    }

    &__divider {
      border-bottom: 1px dashed #c5c8ca;
      margin: 30px;
    }

    &__menu {
      display: flex;
      flex-direction: column;
      margin: 0 30px;

      &__menu-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 10px;

        $this: &;

        &--no-margin {
          margin-bottom: 0;
        }

        &:hover {
          & > #{$this}__content #{$this}__content__title {
            color: #409EFF;
          }
        }

        &__image {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f4f4f4;
          border-radius: 5px;
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }

        &__content {
          display: flex;
          flex-direction: column;

          &__title {
            transition: .3s all;
            color: #444;
            font-size: 15px;
            font-weight: 500;
          }

          &__subtitle {
            color: #777;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .topbar {
    &__header {
      z-index: 1;
      height: 65px !important;
      padding: 0 25px;

      &--fixed {
        left: 265px;
      }

      &__control {
        &__icon {
          padding: 6px;
        }
      }
    }
  }
}

// Animation
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.slide-leave-active, .slide-enter-active {
  transition: .3s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}
</style>

<script>
import { TOGGLE_SIDEBAR } from '@/store/sidebar.module.js'
// Vuex state getter
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      showProfile: false,
      showHeader: false,
      user: {
        name: '',
        position: '',
        email: ''
      },
      showSetting: false
    }
  },
  computed: {
    ...mapGetters(['show'])
  },
  methods: {
    handleCompanyDetails () {
      this.$router.push({ name: 'company-details' }).catch(() => {})
    },
    handleConfigurations () {
      this.$router.push({ name: 'configurations' }).catch(() => {})
    },
    handleLogout () {
      localStorage.removeItem('user')
      this.$router.push({ name: 'login' })
    },
    handleMyProfile () {
      if (this.$route.name !== 'user-profile-personal') this.$router.push({ name: 'user-profile' })
    },
    handleShowProfile () {
      this.showProfile = true
      this.loadItem()
    },
    toggleSidebar () {
      this.$store.dispatch(TOGGLE_SIDEBAR, !this.show)
    },
    toggleHeader () {
      this.showHeader = !this.showHeader
    },

    loadItem () {
      const user = JSON.parse(localStorage.getItem('user'))
      this.user.name = user.name
      this.user.position = user.position
      this.user.email = user.email
      this.user.avatar_url = user.avatar_url
    }
  },
  mounted () {
    this.loadItem()
    const permission = JSON.parse(localStorage.getItem('user')).permissions.find(perm => perm.code === 'SYSTEMS')
    if (permission) this.showSetting = true
    else this.showSetting = false
  }
}
</script>
