export const MENU = [
  {
    id: 1,
    name: 'Dashboard',
    type: 'section'
  },
  {
    id: 2,
    name: 'Dashboard',
    type: 'link',
    icon: 'el-icon-s-platform',
    link: '/dashboard',
    code: 'DASHBOARD'
  },
  {
    id: 3,
    name: 'Stax Application',
    type: 'section'
  },
  {
    id: 4,
    name: 'Inventory',
    type: 'menu',
    icon: 'el-icon-takeaway-box',
    show: false,
    children: [
      {
        id: 1,
        name: 'Products',
        type: 'link',
        icon: 'el-icon-box',
        link: '/inventory/products',
        code: 'PRODUCTS'
      },
      {
        id: 2,
        name: 'Variants',
        type: 'link',
        icon: 'el-icon-receiving',
        link: '/inventory/variants',
        code: 'VARIANTS'
      },
      {
        id: 3,
        name: 'Price List',
        type: 'link',
        icon: 'el-icon-document-copy',
        link: '/inventory/price-lists',
        code: 'PRICE_LISTS'
      }
    ]
  },
  {
    id: 5,
    name: 'Sales Order',
    type: 'menu',
    icon: 'el-icon-tickets',
    show: false,
    children: [
      {
        id: 1,
        name: 'Orders',
        type: 'link',
        icon: 'el-icon-shopping-cart-1',
        link: '/sales-order/orders',
        code: 'ORDERS'
      },
      {
        id: 2,
        name: 'Invoices',
        type: 'link',
        icon: 'el-icon-document',
        link: '/sales-order/invoices',
        code: 'INVOICES'
      },
      {
        id: 3,
        name: 'Shipments',
        type: 'link',
        icon: 'el-icon-truck',
        link: '/sales-order/shipments',
        code: 'SHIPMENTS'
      }
    ]
  },
  {
    id: 6,
    name: 'Stock Control',
    type: 'menu',
    icon: 'el-icon-box',
    show: false,
    children: [
      {
        id: 1,
        name: 'Purchase Order',
        type: 'link',
        icon: 'el-icon-edit-outline',
        link: '/stock-control/purchase-orders',
        code: 'PURCHASE_ORDERS'
      },
      {
        id: 2,
        name: 'Stock Adjustment',
        type: 'link',
        icon: 'el-icon-edit',
        link: '/stock-control/stock-adjustments',
        code: 'STOCK_ADJUSTMENT'
      }
    ]
  },
  {
    id: 11,
    name: 'Projects',
    type: 'menu',
    icon: 'el-icon-folder',
    code: 'PROJECTS',
    show: false,
    children: [
      {
        id: 1,
        name: 'Summary',
        type: 'link',
        icon: 'el-icon-s-data',
        link: '/projects/summary',
        code: 'PROJECT_SUMMARY'
      },
      {
        id: 2,
        name: 'List',
        type: 'link',
        icon: 'el-icon-document-copy',
        link: '/projects',
        code: 'PROJECT_SUMMARY'
      }
    ]
  },
  {
    id: 10,
    name: 'Transactions',
    type: 'link',
    icon: 'el-icon-s-order',
    link: '/transactions',
    code: 'COMPANIES'
  },
  {
    id: 7,
    name: 'Relationship',
    type: 'section'
  },
  {
    id: 8,
    name: 'Company',
    type: 'link',
    icon: 'el-icon-school',
    link: '/relationship/company',
    code: 'COMPANIES'
  },
  {
    id: 9,
    name: 'Customer Groups',
    type: 'link',
    icon: 'el-icon-office-building',
    link: '/relationship/customer-groups',
    code: 'CUSTOMER_GROUPS'
  }
]
