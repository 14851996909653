<template>
  <el-aside width="265px" :class="{ 'el-aside--show': show }">
    <Brand class="hidden-sm-and-down"></Brand>
    <BaseMenu :data="menu"></BaseMenu>
  </el-aside>
</template>

<style lang="scss" scoped>
.el-aside {
  background: #0e0c28;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -265px;
  transition: .5s all;
  z-index: 1;

  &--show {
    left: 0 !important;
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: .3s;
}
.slide-enter {
  transform: translate(-100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

@media screen and (min-width: 1200px) {
  .el-aside {
    z-index: -1;
    left: 0;
  }
}
</style>

<script>
// components import
import Brand from './Brand'

// Constants
import { MENU } from '@/constants/menu'

// Vuex state getter
import { mapGetters } from 'vuex'

export default {
  components: {
    Brand
  },
  data () {
    return {
      menu: []
    }
  },
  computed: {
    ...mapGetters(['show', 'minimize'])
  },
  methods: {
    menuValidation (menu, nMenu, validation) {
      for (let i = 0; i < menu.length; i++) {
        const item = menu[i]

        if (item.type === 'section') {
          nMenu.push(item)
          continue
        }

        if (item.type === 'link') {
          const valid = validation.permissions.find(perm => perm.code === item.code && perm.permission === 'R')

          if (!valid) {
            continue
          }

          nMenu.push(item)
          continue
        }

        if (item.type === 'menu') {
          const copy = Object.assign({}, item)
          copy.children = []
          nMenu.push(copy)
          this.menuValidation(item.children, copy.children, validation)
        }
      }
    }
  },
  created () {
    // const user = JSON.parse(localStorage.getItem('user'))
    this.menu = MENU
  }
}
</script>
