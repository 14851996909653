<template>
  <el-container>
    <Aside></Aside>
    <Container></Container>
  </el-container>
</template>

<script>
// components import
import Aside from './aside/Aside'
import Container from './container/Container'

export default {
  components: {
    Aside,
    Container
  }
}
</script>
